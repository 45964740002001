<template>
  <section class="feedback-wrap">
    <!-- <side-bar/>
    <section class="feedback-content">
      <router-view />
    </section> -->
    <router-view />
  </section>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  }
}
</script>

<style lang="scss">
@import '@/styles/index';
.feedback-wrap {
  width: 100%;
  height: 100%;
  /* padding: 30px 30px;
  display: flex;
  .feedback-content {
    width: 100%;
    min-height: $contentHeight;
    margin-left: 30px;
  } */
}
</style>
